import React, { useEffect, useState } from 'react';
import "../../cards/styles/ProductCard.css";
import "./styles/Categories.css";

const Categories = () => {

    return (
        <div className="product-card">
            <h2>Лазерна Єпіляція</h2>
            <h2>Лазерне омолодження</h2>
            <h2>Антицеллюлітний массаж</h2>
            <h2>Електроєпіляція</h2>
        </div>
    );
};

export default Categories;