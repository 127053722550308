import React from 'react';
//import './styles/marketPage.css'; // Здесь будут стили для карточки

const ServerFiles = ({ product }) => {
    return (
        <div >
            <h2>testtesttest</h2>
            <p>server_files_here</p>
            <button className="buy-button">download launcher</button>
        </div>
    );
};

export default ServerFiles;
